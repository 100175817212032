import React, { useEffect } from 'react';
import Header from '../header/header';
import HeroSection from '../herosection/herosection';
import Services from '../services/services';
import Reasons from '../reasons/reasons';
import Steps from '../steps/steps';
import Footer from '../footer/footer';
import { useLocation } from 'react-router-dom';
import Contact from '../header/contact';

const Homepage = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#service') {
      const serviceSection = document.getElementById('service');
      if (serviceSection) {
        serviceSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <div className='scroll-container'>
      <Header />
      <HeroSection />
      <Steps />
      <div id='service'>
        <Services />
      </div>
      <Reasons />
      <Contact></Contact>
      <Footer />
    </div>
  );
};

export default Homepage;
