import React, { useState } from "react";
import "../dashboard/dashboard.css";
import AdminSidebar from "./adminsidebar.js";
import AdminStorage from "./adminstorage.js";
import AdminLogistics from "./adminlogistics.js";
import AdminTrip from "./admintrip.js";
import JourneyInventory from "./journeyinventory.js";
import VehicleInventory from "./vehicleinventory.js";
import { Outlet } from "react-router-dom";
import AdminRouteInventory from "./routeinventory/adminrouteinventory.js";

function AdminDashboard() {
  const init_selection =
    localStorage.adminSelection === undefined
      ? "Trips"
      : localStorage.adminSelection;
  const [selectedComponent, setSelectedComponent] = useState(init_selection);

  const renderComponent = () => {
    localStorage.adminSelection = selectedComponent;
    switch (selectedComponent) {
      case "Journey":
        return <JourneyInventory />;
      case "Vehicles":
        return <VehicleInventory />;
      case "Passengers":
        return <AdminTrip />;
      case "Storage":
        return <AdminStorage />;
      case "Logistics":
        return <AdminLogistics></AdminLogistics>;
      case "Routes":
        return <AdminRouteInventory />;
      default:
        return <JourneyInventory />;
    }
  };

  return (
    <div>
      <div className="dashboard">
        <AdminSidebar
          setSelectedComponent={setSelectedComponent}
        ></AdminSidebar>
        <div className="main-content ">{renderComponent()}</div>
      </div>
    </div>
  );
}

export default AdminDashboard;
