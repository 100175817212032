import React, { useEffect, useState } from "react";
import "./admin_new.css";
import { IoCloseCircle } from "react-icons/io5";
import { createJourney, fetchVehicles } from "../../service/journey";
import {
  fetchRoutes,
  fetchTravellingInfo,
  fetchTravellingTo,
  fetchUniqueRoutes,
} from "../../service/routes";

function NewJourney({ onClose }) {
  const [bookingData, setBookingData] = useState({
    uid: "",
    vehicleNumber: "",
    licenceNumber: "",
    from: "",
    destination: "",
    createdDate: new Date(),
    departureDate: new Date(),
    departureTime: "",
    vehicleType: "",
    vehicleStatus: "",
    status: "Awaiting",
    driversDetails: {},
    reviewRating: {},
  });
  const [vehicles, setVehicles] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [routesMain, setRoutesMain] = useState([]);
  const [filteredDestinations, setFilteredDestinations] = useState([]);
  const [travellingInfo, setTravellingInfo] = useState([]);

  const handleVehicleChanged = (chg) => {
    const _vehicle = vehicles.find((x) => x.vehicleNumber === chg.target.value);
    setBookingData({
      ...bookingData,
      vehicleNumber: chg.target.value,
      licenceNumber: _vehicle.licenceNumber,
      vehicleStatus: _vehicle.status,
      driversDetails: _vehicle.driversDetails,
      vehicleType: _vehicle.vehicleType,
    });
  };
  const handleFromChange = (e) => {
    const selectedFrom = e.target.value;
    setBookingData({ ...bookingData, from: selectedFrom });
    const destinations = fetchTravellingTo(routesMain, selectedFrom);
    setFilteredDestinations(destinations);
  };
  const handleToChange = (e) => {
    setBookingData({
      ...bookingData,
      destination: e.target.value,
    });
    const routeInfo = fetchTravellingInfo(
      routesMain,
      e.target.value,
      bookingData.from
    );
    if(routeInfo.travellingInfo)
      setTravellingInfo(routeInfo.travellingInfo);
  };
  const handleTravellingInfoChange = (e) => {
    setBookingData({
      ...bookingData,
      routeId: e.target.value,
    });
    console.log(e.target.value);
  };
  const handleSubmit = async () => {
    let result = await createJourney(bookingData);
    if (result.status === 200) {
      alert(result.data);
      setBookingData({
        uid: "",
        vehicleNumber: "",
        licenceNumber: "",
        from: "",
        destination: "",
        createdDate: Date(),
        departureDate: Date(),
        departureTime: "",
        vehicleType: "",
        status: "",
        vehicleStatus: "Awaiting",
        driversDetails: {},
        reviewRating: Object,
      });
      onClose();
    } else {
      alert(result.data);
    }
  };
  useEffect(() => {
    const getVehicles = async () => {
      const data = await fetchVehicles();
      setVehicles(data);
    };
    const getRoutes = async () => {
      const fetchedRoutes = await fetchRoutes();
      const from = fetchUniqueRoutes(fetchedRoutes);
      setRoutes(from);
      setRoutesMain(fetchedRoutes);
    };
    getRoutes();
    getVehicles();
  }, []);
  return (
    <div className="admin-modal-overlay">
      <div className="admin-dialog-container">
        <div className="modal-header">
          <span className="close-button" onClick={onClose}>
            <IoCloseCircle style={{ margin: "10px 5px 5px 5px" }} />
          </span>{" "}
          <div className="logistic-header">
            <h5>Create Journey</h5>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="modal-row">
            <div className="modal-field">
              <select
                className="modal-input"
                onChange={handleFromChange}
                value={bookingData.from}
              >
                <option value="">--Select Station--</option>
                {routes.map((route) => (
                  <option key={route} value={route}>
                    {route}
                  </option>
                ))}
              </select>
            </div>

            <div className="modal-field">
              <select
                className="modal-input"
                onChange={(e) => handleToChange(e)}
                value={bookingData.destination}
              >
                <option value="">--Select Destination--</option>
                {filteredDestinations.map((destination, index) => (
                  <option key={index} value={destination}>
                    {destination}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="modal-row">
            <div className="modal-field">
              <input
                type="date"
                value={bookingData.departureDate}
                placeholder="Trip Date"
                onChange={(e) =>
                  setBookingData({
                    ...bookingData,
                    departureDate: e.target.value,
                  })
                }
                className="modal-input"
                required
              />
            </div>
            <div className="modal-field">
              <select
                className="modal-input"
                onChange={(e) => handleTravellingInfoChange(e)}
                value={bookingData.routeId}
              >
                <option value="">--Select Travelling Info--</option>
                {travellingInfo.map((destination, index) => (
                  <option key={index} value={destination.vehicleNumber}>
                    {destination.vehicleType} - {destination.vehicleNumber} ({destination.departureTime})
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="logistic-header">
            <h5>Vehicle Details</h5>
          </div>
          <br></br>
          <div className="modal-row">
            <div className="modal-field">
              <select
                type="text"
                onChange={(e) => handleVehicleChanged(e)}
                className="modal-input"
                required
              >
                <option>--Select Vehicle--</option>
                {vehicles.map((x) => (
                  <option
                    value={x.vehicleNumber}
                  >{`${x.vehicleNumber} (${x.licenceNumber})`}</option>
                ))}
              </select>
            </div>
            <div className="modal-field">
              <input
                placeholder="Licence Number"
                value={bookingData.licenceNumber}
                className="modal-input"
                readOnly={true}
              />
            </div>
            <div className="modal-field">
              <input
                type="text"
                placeholder="Vehicle Type"
                name="name"
                value={bookingData.vehicleType}
                className="modal-input"
                readOnly={true}
              />
            </div>
          </div>
          <div className="modal-row">
            <div className="modal-field">
              <input
                type="text"
                placeholder="Vehicle Status"
                name="name"
                value={bookingData.vehicleStatus}
                className="modal-input"
                readOnly={true}
              />
            </div>
          </div>
          <br></br>
          <div className="logistic-header">
            <h5>Driver Details</h5>
          </div>
          <br></br>
          <div className="modal-row">
            <div className="modal-field">
              <input
                type="text"
                placeholder="Fullname"
                value={bookingData.driversDetails.fullname}
                onChange={(e) =>
                  setBookingData({
                    ...bookingData,
                    driversDetails: {
                      fullname: e.target.value,
                      phone: bookingData.driversDetails.phone,
                    },
                  })
                }
                className="modal-input"
                required
              />
            </div>
            <div className="modal-field">
              <input
                type="text"
                placeholder="Phone Number"
                value={bookingData.driversDetails.phone}
                onChange={(e) =>
                  setBookingData({
                    ...bookingData,
                    driversDetails: {
                      phone: e.target.value,
                      fullname: bookingData.driversDetails.fullname,
                    },
                  })
                }
                className="modal-input"
                required
              />
            </div>
          </div>
          <br></br>
          <button type="submit" className="admin-modal-button">
            Create Journey
          </button>
        </form>
      </div>
    </div>
  );
}

export default NewJourney;
