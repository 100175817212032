import React from "react";
import { BsEyeSlash, BsEye, BsPrinterFill } from "react-icons/bs";
import { MdDelete, MdEditSquare } from "react-icons/md";
import "../datatable.css";
import "../adminlogistics.css";
import "../vehicle.css";

function TableRow({ route, onView,onDelete, onUpdate, isSelected }) {
  let travellingInfo=[];
  if(route.travellingInfo){
    travellingInfo=route.travellingInfo;
  }
  return (
    <tr className={isSelected ? "selected-row" : ""}>
      <td><p>{route.travellingFrom}</p></td>
      <td><p>{route.travellingTo}</p></td>
      <td><p>{travellingInfo.map((info)=>{
        return (<div style={{marginBottom:'10px'}}>
            <p>{info.vehicleNumber} - <span style={{fontWeight:'bold'}}>{info.vehicleType}</span> (<i>{info.departureTime}</i>) <br></br>
            <span style={{color:'darkgreen',fontWeight:'bold'}}>{new Intl.NumberFormat().format(info.price)} NGN</span></p> 
        </div>)
      })}</p></td>
      <td className="admin-action">
        <div className="admin-action-main">
          <MdEditSquare onClick={() => onUpdate(route)} style={{ cursor: "pointer", marginLeft: "5px" }} />
          <MdDelete style={{ cursor: "pointer", marginLeft: "5px" }} onClick={()=>onDelete(route.uid)}/>
        </div>
      </td>
    </tr>
  );
}

export default TableRow;
