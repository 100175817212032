import React, { useEffect, useState } from "react";
import "./datatable.css";
import "./adminlogistics.css";
import NewStorage from "./newstorageform";
import {
  BsCheckSquareFill,
  BsCalendar2Check,
  BsEye,
  BsEyeSlash,
  BsExclamationSquareFill,
  BsInfoCircle,
  BsPrinterFill,
} from "react-icons/bs";
import {
  checkCompleted,
  formatDate,
  gotoAdminPage,
} from "../../service/general";
import {
  MdCancel,
  MdClearAll,
  MdContentCopy,
  MdEditSquare,
  MdTrackChanges,
} from "react-icons/md";
import { fetchAdminStorage, updatestorage } from "../../service/logistics";

function TableRow({ booking, onView, onUpdate, isSelected }) {
  return (
    <tr className={isSelected ? "selected-row" : ""}>
      <td>
        <div className="person-profile">
          <h3>{booking.fullname}</h3>
          <p>
            {booking.phone}{" "}
            <MdContentCopy
              className="phoneCopy"
              onClick={() => navigator.clipboard.writeText(booking.phone)}
            ></MdContentCopy>
          </p>
          <p className="email">{booking.email}</p>
        </div>
      </td>
      <td>
        <div>
          <img
            src={booking.imageLoc}
            alt="store"
            className="admin-storage-img"
          />
          <p className="admin-storage-desc">
            <b>{booking.itemDescription}</b>
          </p>
          <p className="admin-storage-wgt">
            Weight (kg): <b>{booking.itemWeightInKg}</b>
          </p>
          <p style={{ fontSize: "10px", marginTop: "10px" }}>
            Status:{" "}
            <b>
              {booking.hasRetrieved
                ? "Item has been retrieved"
                : booking.status}
            </b>
          </p>
        </div>
      </td>
      <td>
        <div className="admin-storage-warehouse">
          <div>
            <p className="admin-storage-warehouse-header">Drop-Off Point</p>
            <p className="admin-storage-warehouse-content">
              {booking.pickUpLocation}
            </p>
          </div>
          <div>
            <p className="admin-storage-warehouse-header">Ware House</p>
            <p className="admin-storage-warehouse-content">
              {booking.deliveryLocation}
            </p>
          </div>
          <div>
            <p className="admin-storage-warehouse-header">Duration</p>
            <p className="admin-storage-warehouse-content">
              {booking.duration} ({booking.period})
            </p>
          </div>
          <div>
            <p className="admin-storage-warehouse-header">
              Expected Retrieval Date
            </p>
            <p className="admin-storage-warehouse-content">
              {new Date(booking.RetrievalDate).toDateString()}
            </p>
          </div>
        </div>
      </td>
      <td>
        <div>
          <p style={{ fontSize: "25px" }}>
            <b>{new Intl.NumberFormat().format(booking.fare)} NGN</b>
          </p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "5px",
              fontSize: "13px",
              marginTop: "5px",
            }}
          >
            <b>{booking.paymentType}</b>
          </p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "5px",
              fontSize: "13px",
              marginTop: "5px",
            }}
          >
            {booking.paymentStatus === "Completed" && (
              <BsCheckSquareFill style={{ color: "green" }}></BsCheckSquareFill>
            )}
            {booking.paymentStatus === "Pending" && (
              <BsCheckSquareFill
                style={{ color: "goldenrod" }}
              ></BsCheckSquareFill>
            )}
            {booking.paymentStatus === "Cancelled" && (
              <MdCancel style={{ color: "red" }}></MdCancel>
            )}
            <span style={{ fontSize: "11px" }}>{booking.paymentStatus}</span>
          </p>
        </div>
      </td>
      <td className="admin-action">
        <div className="admin-action-main">
          {isSelected ? (
            <BsEyeSlash
              onClick={() => onView(booking)}
              style={{ cursor: "pointer" }}
            ></BsEyeSlash>
          ) : (
            <BsEye
              onClick={() => onView(booking)}
              style={{ cursor: "pointer" }}
            ></BsEye>
          )}
          <MdEditSquare
            onClick={() => onUpdate(booking)}
            style={{ cursor: "pointer", marginLeft: "5px" }}
          ></MdEditSquare>
          <BsPrinterFill
            style={{ cursor: "pointer", marginLeft: "5px" }}
          ></BsPrinterFill>
        </div>
      </td>
    </tr>
  );
}

function AdminStorage() {
  const [bookings, bookingsChanged] = useState([
    {
      uid: String,
      fullname: String,
      email: String,
      phone: String,
      ownership: String,
      period: String,
      duration: Number,
      itemDescription: String,
      itemWeightInKg: Number,
      createdDate: Date,
      dropOffDate: Date,
      RetrievalDate: Date,
      deliveryLocation: String,
      pickUpLocation: String,
      fare: Number,
      isOnlineBooking: Boolean,
      review: String,
      rating: String,
      attendant: String,
      status: String,
      bookingRef: String,
      imageLoc: String,
      hasRetrieved: false,
    },
  ]);
  const [updateMode, setUpdateMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [isNewBooking, setIsNewBooking] = useState(false);
  const [filterStatus, setFilterStatus] = useState("all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const filteredData = bookings.filter((booking) => {
    try {
      const matchesSearchTerm =
        booking.fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.driverName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.from.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.destination.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.driverName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.phone.includes(searchTerm);
      const matchesStatus =
        filterStatus === "all" || booking.status.toLowerCase() === filterStatus;

      const matchesDate =
        (!startDate || new Date(booking.date) >= new Date(startDate)) &&
        (!endDate || new Date(booking.date) <= new Date(endDate));

      return matchesSearchTerm && matchesStatus && matchesDate;
    } catch {
      return true;
    }
  });

  // Handle viewing booking details
  const handleView = (booking) => {
    var model =
      selectedBooking === null || selectedBooking.uid !== booking.uid
        ? booking
        : null;
    setSelectedBooking(model);
    setUpdateMode(false);
  };
  const handleUpdate = (booking) => {
    handleView(booking);
    setUpdateMode(true);
  };

  // Handle deleting a booking
  const handleDelete = (id) => {
    const updatedData = bookings.filter((booking) => booking.id !== id);
    bookingsChanged(updatedData);
    alert("Booking deleted successfully!");
  };

  // Handle new booking form toggle
  const handleNewBooking = () => {
    setIsNewBooking(true);
    setSelectedBooking(null);
  };

  // Handle booking submission
  const handleBookingSubmit = (newBooking) => {
    bookingsChanged([...bookings, newBooking]);
    setIsNewBooking(false);
    alert("New booking added successfully!");
  };
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  const handleDateSubmit = () => {
    console.log("Date filter applied:", startDate, endDate);
  };

  const handleTodayClick = () => {
    const today = new Date().toISOString().split("T")[0];
    setStartDate(today);
    setEndDate(today);
  };
  const handleDateClear = () => {
    setStartDate("");
    setEndDate("");
  };
  useEffect(() => {
    const fetchStorageDetails = async () => {
      var data = await fetchAdminStorage();
      bookingsChanged(data);
    };
    fetchStorageDetails();
  }, []);

  return (
    <div className="invoice-table">
      <SearchFilter
        setSearchTerm={setSearchTerm}
        onNewBooking={handleNewBooking}
        setFilterStatus={setFilterStatus}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        handleDateSubmit={handleDateSubmit}
        handleTodayClick={handleTodayClick}
        handleDateClear={handleDateClear}
        startDate={startDate}
        endDate={endDate}
      />
      <div
        className="scrollable-container"
        style={{ maxHeight: "70vh", overflowY: "auto", width: "100%" }}
      >
        <table>
          <thead>
            <tr>
              <th>Customer Details</th>
              <th>Details</th>
              <th>Info</th>
              <th>Payment Details</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((booking, index) => (
              <React.Fragment key={index}>
                <TableRow
                  booking={booking}
                  onView={handleView}
                  onDelete={handleDelete}
                  onUpdate={handleUpdate}
                  isSelected={
                    selectedBooking !== null &&
                    selectedBooking.uid === booking.uid
                  }
                />
                {selectedBooking !== null &&
                  selectedBooking.uid === booking.uid && (
                    <tr>
                      <td colSpan="10">
                        <TableRowDetails
                          booking={booking}
                          updateMode={updateMode}
                        />
                      </td>
                    </tr>
                  )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>

      {isNewBooking && (
        <NewStorage
          show={isNewBooking}
          onClose={() => setIsNewBooking(false)}
        />
      )}
    </div>
  );
}

function SearchFilter({
  setSearchTerm,
  onNewBooking,
  setFilterStatus,
  handleStartDateChange,
  handleEndDateChange,
  handleDateSubmit,
  handleDateClear,
  startDate,
  endDate,
}) {
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="header">
      <h2>Storage History</h2>
      <div className="search-filter">
        <div className="search">
          <input
            type="text"
            placeholder="Search"
            className="search-input"
            onChange={handleSearchChange}
          />
          <input
            type="date"
            className="date-input"
            value={startDate}
            onChange={handleStartDateChange}
          />
          <input
            type="date"
            className="date-input"
            value={endDate}
            onChange={handleEndDateChange}
          />
          <button
            className="admin-clear-btn"
            onClick={handleDateClear}
            title="Clear filter"
          >
            <MdClearAll></MdClearAll>
          </button>
          <button className="submit-btn" onClick={handleDateSubmit}>
            Submit
          </button>

          <button className="new-invoice-btn" onClick={onNewBooking}>
            Storage Request
          </button>
        </div>
        <div className="filters">
          <button className="active" onClick={() => setFilterStatus("all")}>
            All Order
          </button>
          <button onClick={() => setFilterStatus("awaiting")}>Awaiting</button>
          <button onClick={() => setFilterStatus("instore")}>In-Store</button>
          <button onClick={() => setFilterStatus("retrieved")}>
            Retrieved
          </button>
        </div>
      </div>
    </div>
  );
}

function TableRowDetails({ booking, updateMode }) {
  const [data, setData] = useState(booking);
  const updateStatus = async (data) => {
    await updatestorage(data);
    gotoAdminPage();
  };
  return (
    <div className="admin-datatable-main">
      <div className="admin-datatable-header">
        <h3 h3>Storage Request ({booking.bookingRef})</h3>
      </div>
      {updateMode ? (
        <div className="admin-datatable-main">
          <div className="admin-datatable-header">
            <h3 h3>Storage Tracker</h3>
          </div>
          <br></br>
          <div className="admin-datatable-details">
            <div>
              <select
                className="modal-input"
                value={data.status}
                onChange={(e) => setData({ ...data, status: e.target.value })}
              >
                <option>--Select Storage Status--</option>
                <option>Awaiting</option>
                <option>In-Store</option>
                <option>Retrieved</option>
              </select>
              <br></br>
              <br></br>
            </div>
          </div>
          <br></br>

          <div className="admin-datatable-header">
            <h3 h3>Storage Cost</h3>
          </div>
          <br></br>
          <div className="admin-datatable-details">
            <div>
              <input
                placeholder="Storage Cost"
                style={{ height: "40px" }}
                type="number"
                className="modal-input"
                value={data.status}
                onChange={(e) => setData({ ...data, fare: e.target.value })}
              />
              <br></br>
              <br></br>
              <button
                className="admin-datatable-header-button"
                onClick={() => updateStatus(data)}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="booked-items" style={{ cursor: "arrow" }}>
          <div>
            <div className="logistic-container-content">
              <p>
                Storage Item <BsInfoCircle></BsInfoCircle>
              </p>
              <div className="logistic-container-row-main storage-header">
                <div>
                  <img
                    src={booking.imageLoc}
                    alt="freezer"
                    className="storage-img"
                  />
                  <p
                    className="logistic-receiver-name"
                    style={{ fontSize: "30px" }}
                  >
                    {new Intl.NumberFormat().format(booking.fare)} NGN
                  </p>
                </div>
                <div className="logistic-container-row">
                  <div>
                    <p className="logistic-row-header">Item Description</p>
                    <p className="logistic-row-content">
                      {booking.itemDescription}
                    </p>
                  </div>
                  <div>
                    <p className="logistic-row-header">Item Weight</p>
                    <p className="logistic-row-content">
                      {booking.itemWeightInKg} (kg)
                    </p>
                  </div>
                  <div>
                    <p className="logistic-row-header">Storage Code</p>
                    <p className="logistic-row-content">{booking.bookingRef}</p>
                  </div>
                </div>
              </div>
              <div className="logistic-divider"></div>
              <p>
                Ware House Information <BsInfoCircle></BsInfoCircle>
              </p>
              <div className="logistic-container-row-between">
                <div>
                  <p className="logistic-row-header">Drop-Off Point</p>
                  <p className="logistic-row-content">
                    {booking.pickUpLocation}
                  </p>
                </div>
                <div>
                  <p className="logistic-row-header">Ware House</p>
                  <p className="logistic-row-content">
                    {booking.deliveryLocation}
                  </p>
                </div>
                <div>
                  <p className="logistic-row-header">Duration</p>
                  <p className="logistic-row-content">
                    {booking.duration} ({booking.period})
                  </p>
                </div>
                <div>
                  <p className="logistic-row-header">Expected Retrieval Date</p>
                  <p className="logistic-row-content">
                    {new Date(booking.RetrievalDate).toDateString()}
                  </p>
                </div>
              </div>
              <div className="logistic-divider"></div>
              <p>
                Storage Tracker <MdTrackChanges></MdTrackChanges>
              </p>
              <div className="logistic-container-row">
                <p className="logistic-row-header">Awaiting</p>
                {checkCompleted("Awaiting", booking.status) ? (
                  <BsCheckSquareFill
                    title="Completed"
                    style={{ color: "lightgreen" }}
                  ></BsCheckSquareFill>
                ) : (
                  <BsExclamationSquareFill
                    title="Pending"
                    style={{ color: "yellow" }}
                  ></BsExclamationSquareFill>
                )}
                <p className="logistic-row-header">In-Store</p>

                {checkCompleted("In-Store", booking.status) ? (
                  <BsCheckSquareFill
                    title="Completed"
                    style={{ color: "lightgreen" }}
                  ></BsCheckSquareFill>
                ) : (
                  <BsExclamationSquareFill
                    title="Pending"
                    style={{ color: "yellow" }}
                  ></BsExclamationSquareFill>
                )}
                <p className="logistic-row-header">Retrieved</p>

                {checkCompleted("Retrieved", booking.status) ? (
                  <BsCheckSquareFill
                    title="Completed"
                    style={{ color: "lightgreen" }}
                  ></BsCheckSquareFill>
                ) : (
                  <BsExclamationSquareFill
                    title="Pending"
                    style={{ color: "yellow" }}
                  ></BsExclamationSquareFill>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminStorage;
