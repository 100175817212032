import React, { useEffect, useState } from "react";
import "./datatable.css";
import "./adminlogistics.css";
import "./vehicle.css";
import { BsEyeSlash, BsEye, BsPrinterFill } from "react-icons/bs";
import { getLastMaintenance, gotoAdminPage } from "../../service/general";
import { MdClearAll, MdEditSquare } from "react-icons/md";
import NewVehicleInventory from "./newvehicle.js";
import { fetchVehicles, updateVehicle } from "../../service/journey.js";

function TableRow({ booking, onView, onUpdate, isSelected }) {
  return (
    <tr className={isSelected ? "selected-row" : ""}>
      <td>
        <div className="person-profile">
          <h4>{booking.vehicleNumber}</h4>
          <p>{booking.licenceNumber}</p>
          <p className="email">{booking.vehicleType}</p>
        </div>
      </td>
      <td>
        <div className="person-profile">
          <h4>{booking.driversDetails.fullname}</h4>
          <p>{booking.driversDetails.phone}</p>
          <p className="email">{booking.driversDetails.email}</p>
        </div>
      </td>
      <td>
        <div className="admin-storage-warehouse">
          <div>
            <p className="admin-storage-warehouse-header">Vehicle Status</p>
            <p className="admin-storage-warehouse-content">{booking.status}</p>
          </div>
          <div>
            <p className="admin-storage-warehouse-header">Last Maintenance</p>
            <p className="admin-storage-warehouse-content">
              {getLastMaintenance(booking)}
            </p>
          </div>
        </div>
      </td>
      <td className="admin-action">
        <div className="admin-action-main">
          {/* {isSelected ? (
            <BsEyeSlash
              onClick={() => onView(booking)}
              style={{ cursor: "pointer" }}
            ></BsEyeSlash>
          ) : (
            <BsEye
              onClick={() => onView(booking)}
              style={{ cursor: "pointer" }}
            ></BsEye>
          )} */}
          <MdEditSquare
            onClick={() => onUpdate(booking)}
            style={{ cursor: "pointer", marginLeft: "5px" }}
          ></MdEditSquare>
          <BsPrinterFill
            style={{ cursor: "pointer", marginLeft: "5px" }}
          ></BsPrinterFill>
        </div>
      </td>
    </tr>
  );
}

function VehicleInventory() {
  const [bookings, bookingsChanged] = useState([
    {
      uid: "",
      vehicleNumber: "",
      licenceNumber: "",
      createdDate: Date(),
      vehicleType: "",
      vehicleImage: "",
      status: "Active",
      driversDetails: {
        fullname: "",
        phone: "",
        nin: "",
        displaypicture: "",
      },
      lastMaintenance: Date,
      lastMaintenanceApprovedBy: String,
    },
  ]);
  const [updateMode, setUpdateMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [isNewBooking, setIsNewBooking] = useState(false);
  const [filterStatus, setFilterStatus] = useState("all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const filteredData = bookings.filter((booking) => {
    try {
      const matchesSearchTerm =
        booking.vehicleNumber
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        booking.vehicleType.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.driversDetails.fullname
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      const matchesStatus =
        filterStatus === "all" ||
        booking.status.toLowerCase() === filterStatus ||
        booking.vehicleType.toLowerCase() === filterStatus;

      const matchesDate =
        (!startDate || new Date(booking.date) >= new Date(startDate)) &&
        (!endDate || new Date(booking.date) <= new Date(endDate));

      return matchesSearchTerm && matchesStatus && matchesDate;
    } catch {
      return true;
    }
  });

  // Handle viewing booking details
  const handleView = (booking) => {
    var model =
      selectedBooking === null || selectedBooking.uid !== booking.uid
        ? booking
        : null;
    setSelectedBooking(model);
    setUpdateMode(false);
  };
  const handleUpdate = (booking) => {
    handleView(booking);
    setUpdateMode(true);
  };

  // Handle deleting a booking
  const handleDelete = (id) => {
    const updatedData = bookings.filter((booking) => booking.id !== id);
    bookingsChanged(updatedData);
    alert("Booking deleted successfully!");
  };

  // Handle new booking form toggle
  const handleNewBooking = () => {
    setIsNewBooking(true);
    setSelectedBooking(null);
  };

  // Handle booking submission
  const handleBookingSubmit = (newBooking) => {
    bookingsChanged([...bookings, newBooking]);
    setIsNewBooking(false);
    alert("New booking added successfully!");
  };
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  const handleDateSubmit = () => {
    console.log("Date filter applied:", startDate, endDate);
  };

  const handleTodayClick = () => {
    const today = new Date().toISOString().split("T")[0];
    setStartDate(today);
    setEndDate(today);
  };
  const handleDateClear = () => {
    setStartDate("");
    setEndDate("");
  };
  useEffect(() => {
    const fetchTripDetails = async () => {
      var data = await fetchVehicles();
      bookingsChanged(data);
    };
    fetchTripDetails();
  }, []);

  return (
    <div className="invoice-table">
      <SearchFilter
        setSearchTerm={setSearchTerm}
        onNewBooking={handleNewBooking}
        setFilterStatus={setFilterStatus}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        handleDateSubmit={handleDateSubmit}
        handleTodayClick={handleTodayClick}
        handleDateClear={handleDateClear}
        startDate={startDate}
        endDate={endDate}
      />
      <div
        className="scrollable-container"
        style={{ maxHeight: "100vh", overflowY: "auto", width: "100%" }}
      >
        <table>
          <thead>
            <tr>
              <th>Vehicle Details</th>
              <th>Driver Details</th>
              <th>Maintenance Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((booking, index) => (
              <React.Fragment key={index}>
                <TableRow
                  booking={booking}
                  // onView={handleView}
                  onDelete={handleDelete}
                  onUpdate={handleUpdate}
                  isSelected={
                    selectedBooking !== null &&
                    selectedBooking.uid === booking.uid
                  }
                />
                {selectedBooking !== null &&
                  selectedBooking.uid === booking.uid && (
                    <tr>
                      <td colSpan="10">
                        <TableRowDetails booking={booking} />
                      </td>
                    </tr>
                  )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>

      {isNewBooking && (
        <NewVehicleInventory
          show={isNewBooking}
          onClose={() => setIsNewBooking(false)}
        />
      )}
    </div>
  );
}

function SearchFilter({
  setSearchTerm,
  onNewBooking,
  setFilterStatus,
  handleStartDateChange,
  handleEndDateChange,
  handleDateSubmit,
  handleTodayClick,
  handleDateClear,
  startDate,
  endDate,
}) {
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="header">
      <h2>Vehicle Inventory</h2>
      <div className="search-filter">
        <div className="search">
          <input
            type="text"
            placeholder="Search"
            className="search-input"
            onChange={handleSearchChange}
          />
          <input
            type="date"
            className="date-input"
            value={startDate}
            onChange={handleStartDateChange}
          />
          <input
            type="date"
            className="date-input"
            value={endDate}
            onChange={handleEndDateChange}
          />
          <button
            className="admin-clear-btn"
            onClick={handleDateClear}
            title="Clear filter"
          >
            <MdClearAll></MdClearAll>
          </button>
          <button className="submit-btn" onClick={handleDateSubmit}>
            Submit
          </button>

          <button className="new-invoice-btn" onClick={onNewBooking}>
            New Vehicle
          </button>
        </div>
        <div className="filters">
          <button className="active" onClick={() => setFilterStatus("all")}>
            All Vehicles
          </button>
          <button onClick={() => setFilterStatus("bus")}>Hiace Buses</button>
          <button onClick={() => setFilterStatus("sienna")}>Sienna</button>
          <button onClick={() => setFilterStatus("active")}>Active</button>
          <button onClick={() => setFilterStatus("inactive")}>Inactive</button>
        </div>
      </div>
    </div>
  );
}

function TableRowDetails({ booking }) {
  const [data, setData] = useState(booking);
  const updateVehicleStatus = async (data) => {
    await updateVehicle(data);
    gotoAdminPage();
  };
  return (
    <div className="admin-datatable-main">
      <div className="admin-datatable-header">
        <h3 h3>Vehicle Details</h3>
        <button onClick={() => updateVehicleStatus(data)}>Save Changes</button>
      </div>
      <br></br>
      <div className="admin-datatable-details">
        <div className="admin-dt-container">
          <div style={{ marginRight: "10px" }}>
            <p className="booking-label">Vehicle Status</p>
            <br></br>
            <select
              className="modal-input"
              value={data.status}
              onChange={(e) => setData({ ...data, status: e.target.value })}
            >
              <option>--Select Vehicle Status--</option>
              <option>Active</option>
              <option>Maintenance</option>
              <option>Inactive</option>
            </select>
          </div>
        </div>
        <div className="admin-dt-container">
          <p className="booking-label">Last Maintenance</p>
          <div style={{ marginRight: "10px" }}>
            <input
              placeholder="Date"
              type="date"
              className="modal-input"
              style={{ height: "40px" }}
              value={data.lastMaintenance}
              onChange={(e) =>
                setData({ ...data, lastMaintenance: e.target.value })
              }
            />
            <br></br>
            <br></br>
            <input
              placeholder="Approved By"
              className="modal-input"
              style={{ height: "40px" }}
              value={data.lastMaintenanceApprovedBy}
              onChange={(e) =>
                setData({ ...data, lastMaintenanceApprovedBy: e.target.value })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VehicleInventory;
