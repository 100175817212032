import React from "react";
import "../dashboard/sidebar.css";
import { logout } from "../../service/users";
import { useNavigate } from "react-router-dom";

const AdminSidebar = ({ setSelectedComponent }) => {
  const changePage=(page)=>{
    localStorage.journeyUid = "";
    setSelectedComponent(page)
  }

  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/");
  };
  return (
    <div className="sidebar">
      <img
        src={require("../../images/logo.png")}
        alt=""
        style={{ width: "150px", borderRadius: "5px", marginBottom: "10px" }}
      />
            <nav>
        <ul>
          
        {/* <li><a href="#" onClick={() => setSelectedComponent('Profile')}>Profile</a></li> */}
          <li><a href="#" onClick={() => setSelectedComponent('Vehicles')}>Vehicle</a></li>
          <li><a href="#" onClick={() => setSelectedComponent('Routes')}>Routes</a></li>
          <li><a href="#" onClick={() => changePage('Passengers')}>Passengers</a></li>
          <li><a href="#" onClick={() => changePage('Journey')}>Journey Manifest</a></li>
          <li><a href="#" onClick={() => changePage('Storage')}>Storage</a></li>
          <li><a href="#" onClick={() => changePage('Logistics')}>Logistics</a></li>
          {/* <li><a href="#" onClick={() => setSelectedComponent('ChangePassword')}>Change Password</a></li> */}
          <li>
            <button onClick={handleLogout} className="logout-button">Logout</button>
          </li>
        </ul>
      </nav>
      {/* <nav>
        <ul>
          <li>
            <Link to="/admindashboard/vehicles">Vehicles</Link>
          </li>
          <li>
            <Link to="/admindashboard/routes">Routes</Link>
          </li>
          <li>
            <p onClick={() => changePage("Passengers")}>
              Passengers
            </p>
          </li>
          <li>
            <Link to="/admindashboard/journey">Journey Manifest</Link>
          </li>
          <li>
            <Link to="/admindashboard/storage">Storage</Link>
          </li>
          <li>
            <Link to="/admindashboard/logistics">Logistics</Link>
          </li>
          <li>
            <Link to="/admindashboard/changepassword">Change Password</Link>
          </li>
          <li>
            <button onClick={handleLogout} className="logout-button">Logout</button>
          </li>
        </ul>
      </nav> */}
    </div>
  );
};

export default AdminSidebar;
