import React, { useEffect, useState } from "react";
import "./datatable.css";
import "./adminlogistics.css";
import {
  BsCheckSquareFill,
  BsEye,
  BsEyeSlash,
  BsExclamationSquareFill,
  BsInfoCircle,
  BsPrinterFill,
} from "react-icons/bs";
import {
  checkCompleted,
  formatDate,
  gotoAdminPage,
} from "../../service/general";
import {
  MdCancel,
  MdClearAll,
  MdContentCopy,
  MdEditSquare,
  MdTrackChanges,
} from "react-icons/md";
import { fetchAdminLogistics, updatelogistic } from "../../service/logistics";
import NewDeliveryForm from "./newdelivery";

function TableRow({ booking, onView, onUpdate, isSelected }) {
  return (
    <tr className={isSelected ? "selected-row" : ""}>
      <td>
        <div className="person-profile">
          <h5 className="admin-logistics-customer">Sender Details</h5>
          <h4 style={{ fontSize: "14px" }}>{booking.fullname}</h4>
          <p style={{ fontSize: "12px" }}>
            {booking.phone}{" "}
            <MdContentCopy
              className="phoneCopy"
              onClick={() => navigator.clipboard.writeText(booking.phone)}
            ></MdContentCopy>
          </p>
          <p className="email" style={{ fontSize: "10px" }}>
            {booking.email}
          </p>
          <h5 className="admin-logistics-customer" style={{ marginTop: "5px" }}>
            Receiver Details
          </h5>
          <h4 style={{ fontSize: "14px" }}>{booking.receiverName}</h4>
          <p style={{ fontSize: "12px" }}>
            {booking.receiverPhone}{" "}
            <MdContentCopy
              className="phoneCopy"
              onClick={() => navigator.clipboard.writeText(booking.phone)}
            ></MdContentCopy>
          </p>
          <p className="email" style={{ fontSize: "10px" }}>
            {booking.receiverEmail}
          </p>
        </div>
      </td>
      <td style={{ minWidth: "200px" }}>
        <div>
          <p className="admin-storage-desc">
            <b>{booking.itemDescription}</b>
          </p>
          <p className="admin-storage-wgt">
            Weight (kg): <b>{booking.itemWeightInKg}</b>
          </p>
          <p style={{ fontSize: "10px", marginTop: "10px" }}>
            Status:{" "}
            <b>
              {booking.hasRetrieved
                ? "Item has been retrieved"
                : booking.status}
            </b>
          </p>
        </div>
      </td>
      <td>
        <div className="admin-storage-warehouse">
          <div>
            <p className="admin-storage-warehouse-header">Drop-Off Point</p>
            <p className="admin-storage-warehouse-content">
              {booking.pickUpLocation}{" "}
              <b style={{ fontSize: "10px" }}>
                ({formatDate(booking.dropOffDate)})
              </b>
            </p>
          </div>
          <div>
            <p className="admin-storage-warehouse-header">Delivery Location</p>
            <p className="admin-storage-warehouse-content">
              {booking.deliveryLocation}
            </p>
          </div>
          <div>
            <p className="admin-storage-warehouse-header">
              Expected Arrival Date
            </p>
            <p className="admin-storage-warehouse-content">
              {new Date(booking.arrivalDate).toDateString()}
            </p>
          </div>
        </div>
      </td>
      <td>
        <div>
          <p style={{ fontSize: "25px" }}>
            <b>{new Intl.NumberFormat().format(booking.fare)} NGN</b>
          </p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "5px",
              fontSize: "13px",
              marginTop: "5px",
            }}
          >
            <b>{booking.paymentType}</b>
          </p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "5px",
              fontSize: "13px",
              marginTop: "5px",
            }}
          >
            {booking.paymentStatus === "Completed" && (
              <BsCheckSquareFill style={{ color: "green" }}></BsCheckSquareFill>
            )}
            {booking.paymentStatus === "Pending" && (
              <BsCheckSquareFill
                style={{ color: "goldenrod" }}
              ></BsCheckSquareFill>
            )}
            {booking.paymentStatus === "Cancelled" && (
              <MdCancel style={{ color: "red" }}></MdCancel>
            )}
            <span style={{ fontSize: "11px" }}>{booking.paymentStatus}</span>
          </p>
        </div>
      </td>
      <td className="admin-action">
        <div className="admin-action-main">
          {isSelected ? (
            <BsEyeSlash
              onClick={() => onView(booking)}
              style={{ cursor: "pointer" }}
            ></BsEyeSlash>
          ) : (
            <BsEye
              onClick={() => onView(booking)}
              style={{ cursor: "pointer" }}
            ></BsEye>
          )}
          <MdEditSquare
            onClick={() => onUpdate(booking)}
            style={{ cursor: "pointer", marginLeft: "5px" }}
          ></MdEditSquare>
          <BsPrinterFill
            style={{ cursor: "pointer", marginLeft: "5px" }}
          ></BsPrinterFill>
        </div>
      </td>
    </tr>
  );
}

function AdminLogistics() {
  const [bookings, bookingsChanged] = useState([
    {
      uid: String,
      fullname: String,
      email: String,
      phone: String,
      receiverName: String,
      receiverEmail: String,
      receiverPhone: String,
      itemDescription: String,
      itemWeightInKg: Number,
      createdDate: Date,
      dropOffDate: Date,
      departureDate: Date,
      arrivalDate: Date,
      deliveryLocation: String,
      pickUpLocation: String,
      fare: Number,
      discount: Number,
      isOnlineBooking: Boolean,
      paymentType: String,
      paymentRef: String,
      paymentStatus: String,
      arrivalRemarks: String,
      review: String,
      rating: String,
      driverName: String,
      attendant: String,
      hasArrived: Boolean,
      status: String,
      bookingRef: String,
    },
  ]);
  const [updateMode, setUpdateMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [isNewBooking, setIsNewBooking] = useState(false);
  const [filterStatus, setFilterStatus] = useState("all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const filteredData = bookings.filter((booking) => {
    try {
      const matchesSearchTerm =
        booking.fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.driverName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.from.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.destination.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.driverName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.phone.includes(searchTerm);
      const matchesStatus =
        filterStatus === "all" || booking.status.toLowerCase() === filterStatus;

      const matchesDate =
        (!startDate || new Date(booking.date) >= new Date(startDate)) &&
        (!endDate || new Date(booking.date) <= new Date(endDate));

      return matchesSearchTerm && matchesStatus && matchesDate;
    } catch {
      return true;
    }
  });

  // Handle viewing booking details
  const handleView = (booking) => {
    var model =
      selectedBooking === null || selectedBooking.uid !== booking.uid
        ? booking
        : null;
    setSelectedBooking(model);
    setUpdateMode(false);
  };
  const handleUpdate = (booking) => {
    handleView(booking);
    setUpdateMode(true);
  };

  // Handle deleting a booking
  const handleDelete = (id) => {
    const updatedData = bookings.filter((booking) => booking.id !== id);
    bookingsChanged(updatedData);
    alert("Booking deleted successfully!");
  };

  // Handle new booking form toggle
  const handleNewBooking = () => {
    setIsNewBooking(true);
    setSelectedBooking(null);
  };

  // Handle booking submission
  const handleBookingSubmit = (newBooking) => {
    bookingsChanged([...bookings, newBooking]);
    setIsNewBooking(false);
    alert("New booking added successfully!");
  };
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  const handleDateSubmit = () => {
    console.log("Date filter applied:", startDate, endDate);
  };

  const handleTodayClick = () => {
    const today = new Date().toISOString().split("T")[0];
    setStartDate(today);
    setEndDate(today);
  };
  const handleDateClear = () => {
    setStartDate("");
    setEndDate("");
  };
  useEffect(() => {
    const fetchLogisticDetails = async () => {
      var data = await fetchAdminLogistics();
      bookingsChanged(data);
    };
    fetchLogisticDetails();
  }, []);

  return (
    <div className="invoice-table">
      <SearchFilter
        setSearchTerm={setSearchTerm}
        onNewBooking={handleNewBooking}
        setFilterStatus={setFilterStatus}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        handleDateSubmit={handleDateSubmit}
        handleTodayClick={handleTodayClick}
        handleDateClear={handleDateClear}
        startDate={startDate}
        endDate={endDate}
      />
      <div
        className="scrollable-container"
        style={{ maxHeight: "70vh", overflowY: "auto", width: "100%" }}
      >
        <table>
          <thead>
            <tr>
              <th>Customer Details</th>
              <th>Details</th>
              <th>Delivery</th>
              <th>Payment Details</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((booking, index) => (
              <React.Fragment key={index}>
                <TableRow
                  booking={booking}
                  onView={handleView}
                  onDelete={handleDelete}
                  onUpdate={handleUpdate}
                  isSelected={
                    selectedBooking !== null &&
                    selectedBooking.uid === booking.uid
                  }
                />
                {selectedBooking !== null &&
                  selectedBooking.uid === booking.uid && (
                    <tr>
                      <td colSpan="10">
                        <TableRowDetails
                          booking={booking}
                          updateMode={updateMode}
                        />
                      </td>
                    </tr>
                  )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>

      {isNewBooking && (
        <NewDeliveryForm
          show={isNewBooking}
          onClose={() => setIsNewBooking(false)}
        />
      )}
    </div>
  );
}

function SearchFilter({
  setSearchTerm,
  onNewBooking,
  setFilterStatus,
  handleStartDateChange,
  handleEndDateChange,
  handleDateSubmit,
  handleDateClear,
  startDate,
  endDate,
}) {
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="header">
      <h2>Logistics History</h2>
      <div className="search-filter">
        <div className="search">
          <input
            type="text"
            placeholder="Search"
            className="search-input"
            onChange={handleSearchChange}
          />
          <input
            type="date"
            className="date-input"
            value={startDate}
            onChange={handleStartDateChange}
          />
          <input
            type="date"
            className="date-input"
            value={endDate}
            onChange={handleEndDateChange}
          />
          <button
            className="admin-clear-btn"
            onClick={handleDateClear}
            title="Clear filter"
          >
            <MdClearAll></MdClearAll>
          </button>
          <button className="submit-btn" onClick={handleDateSubmit}>
            Submit
          </button>

          <button className="new-invoice-btn" onClick={onNewBooking}>
            New Delivery
          </button>
        </div>
        <div className="filters">
          <button className="active" onClick={() => setFilterStatus("all")}>
            All Order
          </button>
          <button onClick={() => setFilterStatus("awaiting")}>Awaiting</button>
          <button onClick={() => setFilterStatus("ontransit")}>
            On Transit
          </button>
          <button onClick={() => setFilterStatus("arrived")}>Arrived</button>
          <button onClick={() => setFilterStatus("delivered")}>
            Delivered
          </button>
        </div>
      </div>
    </div>
  );
}

function TableRowDetails({ booking, updateMode }) {
  const [data, setData] = useState(booking);
  const updateStatus = async (data) => {
    await updatelogistic(data);
    gotoAdminPage();
  };
  return (
    <div className="admin-datatable-main">
      <div className="admin-datatable-header">
        <h3 h3>Delivery Request ({booking.bookingRef})</h3>
        <p>
          {booking.hasArrived ? (
            <BsCheckSquareFill
              title="Completed"
              style={{ color: "lightgreen" }}
            ></BsCheckSquareFill>
          ) : (
            <BsExclamationSquareFill
              title="Pending"
              style={{ color: "yellow" }}
            ></BsExclamationSquareFill>
          )}
        </p>
      </div>
      {updateMode ? (
        <div className="admin-datatable-main">
          <div className="admin-datatable-header">
            <h3 h3>Delivery Tracker</h3>
          </div>
          <div className="admin-datatable-details">
            <div>
              <select
                className="modal-input"
                value={data.status}
                onChange={(e) => setData({ ...data, status: e.target.value })}
              >
                <option>--Select Delivery Status--</option>
                <option>Awaiting</option>
                <option>On-Transit</option>
                <option>Arrived Destination</option>
                <option>Delivered</option>
              </select>
            </div>
          </div>
          <br></br>

          <div className="admin-datatable-header">
            <h3 h3>Delivery Cost</h3>
          </div>
          <div className="admin-datatable-details">
            <input
              placeholder="Delivery Cost"
              style={{ height: "40px" }}
              type="number"
              className="modal-input"
              value={data.fare}
              onChange={(e) => setData({ ...data, fare: e.target.value })}
            />
          </div>
          <br></br>
          <div className="admin-datatable-header">
            <h3 h3>Payment Mode</h3>
          </div>
          <div className="admin-datatable-details">
            <div>
              <select
                type="text"
                value={data.paymentType}
                onChange={(e) =>
                  setData({ ...data, paymentType: e.target.value })
                }
                className="modal-input"
                required
              >
                <option>--Payment Mode--</option>
                <option>POS</option>
                <option>Bank Transfer</option>
                <option>Cash</option>
              </select>
              <br></br>
              <br></br>
              <br></br>
              <button
                className="admin-datatable-header-button"
                onClick={() => updateStatus(data)}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="booked-items" style={{ cursor: "arrow" }}>
          <div>
            <div className="logistic-container-content">
              <p>
                Courier Information <BsInfoCircle></BsInfoCircle>
              </p>
              <div className="logistic-container-row-main">
                <div>
                  <p className="logistic-receiver-name">
                    {booking.receiverName}
                  </p>
                  <p className="logistic-phone">{booking.phone}</p>
                  <p
                    className="logistic-receiver-name"
                    style={{ fontSize: "30px" }}
                  >
                    {new Intl.NumberFormat().format(booking.fare)} NGN
                  </p>
                </div>
                <div className="logistic-container-row">
                  <div>
                    <p className="logistic-row-header">Item Description</p>
                    <p className="logistic-row-content">
                      {booking.itemDescription}
                    </p>
                  </div>
                  <div>
                    <p className="logistic-row-header">Item Weight</p>
                    <p className="logistic-row-content">
                      {booking.itemWeightInKg} (kg)
                    </p>
                  </div>
                  <div>
                    <p className="logistic-row-header">Delivery Code</p>
                    <p className="logistic-row-content">{booking.bookingRef}</p>
                  </div>
                </div>
              </div>
              <div className="logistic-divider"></div>
              <p>
                Delivery Information <BsInfoCircle></BsInfoCircle>
              </p>
              <div className="logistic-container-row-between">
                <div>
                  <p className="logistic-row-header">Drop-Off Point</p>
                  <p className="logistic-row-content">
                    {booking.pickUpLocation}
                  </p>
                </div>
                <div>
                  <p className="logistic-row-header">Delivery Location</p>
                  <p className="logistic-row-content">
                    {booking.deliveryLocation}
                  </p>
                </div>
                <div>
                  <p className="logistic-row-header">Drop Off Date</p>
                  <p className="logistic-row-content">
                    {new Date(booking.dropOffDate).toDateString()}
                  </p>
                </div>
                <div>
                  <p className="logistic-row-header">Departure Date</p>
                  <p className="logistic-row-content">
                    {new Date(booking.departureDate).toDateString()}
                  </p>
                </div>
                <div>
                  <p className="logistic-row-header">Expected Delivery Date</p>
                  <p className="logistic-row-content">
                    {new Date(booking.arrivalDate).toDateString()}
                  </p>
                </div>
              </div>
              <div className="logistic-divider"></div>
              <p>
                Delivery Tracker <MdTrackChanges></MdTrackChanges>
              </p>
              <div className="logistic-container-row">
                <p className="logistic-row-header">Awaiting</p>
                {checkCompleted("Awaiting", booking.status) ? (
                  <BsCheckSquareFill
                    title="Completed"
                    style={{ color: "lightgreen" }}
                  ></BsCheckSquareFill>
                ) : (
                  <BsExclamationSquareFill
                    title="Pending"
                    style={{ color: "yellow" }}
                  ></BsExclamationSquareFill>
                )}
                <p className="logistic-row-header">On Transit</p>

                {checkCompleted("Transit", booking.status) ? (
                  <BsCheckSquareFill
                    title="Completed"
                    style={{ color: "lightgreen" }}
                  ></BsCheckSquareFill>
                ) : (
                  <BsExclamationSquareFill
                    title="Pending"
                    style={{ color: "yellow" }}
                  ></BsExclamationSquareFill>
                )}
                <p className="logistic-row-header">Arrived Destination</p>

                {checkCompleted("Arrived", booking.status) ? (
                  <BsCheckSquareFill
                    title="Completed"
                    style={{ color: "lightgreen" }}
                  ></BsCheckSquareFill>
                ) : (
                  <BsExclamationSquareFill
                    title="Pending"
                    style={{ color: "yellow" }}
                  ></BsExclamationSquareFill>
                )}
                <p className="logistic-row-header">Delivered</p>

                {checkCompleted("Delivered", booking.status) ? (
                  <BsCheckSquareFill
                    title="Completed"
                    style={{ color: "lightgreen" }}
                  ></BsCheckSquareFill>
                ) : (
                  <BsExclamationSquareFill
                    title="Pending"
                    style={{ color: "yellow" }}
                  ></BsExclamationSquareFill>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminLogistics;
