import React, { useEffect, useState } from "react";
import "../admin_new.css";
import { IoAddCircle, IoCloseCircle } from "react-icons/io5";
import { createRoute, updateRoute } from "../../../service/routes";
import { MdDelete } from "react-icons/md";

const NewRouteInventory = ({ onClose, routeDetails, updateMood }) => {

  const [formData, setFormData] = useState({
    uid:"",
    travellingFrom: "",
    travellingTo: "",
    categories: [
      { vehicleNumber: "", departureTime: "", vehicleType: "", price: "" },
    ],
  });
  useEffect(()=>{
    if(routeDetails){
      setFormData({
        uid:routeDetails.uid,
        travellingFrom: routeDetails.travellingFrom,
        travellingTo: routeDetails.travellingTo,
        categories: routeDetails.travellingInfo
      })
    }
  },[routeDetails])
  const [errors, setErrors] = useState({});

  const addCategory = () => {
    setFormData({
      ...formData,
      categories: [
        ...formData.categories,
        { uid:"",vehicleNumber: "", departureTime: "", vehicleType: "", price: "" },
      ],
    });
  };

  const handleCategoryChange = (index, field, value) => {
    const newCategories = formData.categories.map((category, i) =>
      i === index ? { ...category, [field]: value } : category
    );
    setFormData({ ...formData, categories: newCategories });
  };

  const removeCategory = (index) => {
    const newCategories = formData.categories.filter((_, i) => i !== index);
    setFormData({ ...formData, categories: newCategories });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.travellingFrom)
      newErrors.travellingFrom = "Travelling from is required.";
    if (!formData.travellingTo)
      newErrors.travellingTo = "Travelling to is required.";

    formData.categories.forEach((category, index) => {
      if (!category.departureTime) {
        newErrors[`categories[${index}].departureTime`] =
          "Departure time is required.";
      }
      if (!category.vehicleType) {
        newErrors[`categories[${index}].vehicleType`] =
          "Vehicle type is required.";
      }
      if (!category.price) {
        newErrors[`categories[${index}].price`] = "Price is required.";
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const createInventory = async () => {
    if (!validateForm()) return;
  
    if (updateMood) {
      const result = await updateRoute(formData);
      if (result.status === 200) {
        alert(result.data);
        resetForm();
        onClose();
      } else {
        alert(result.data);
      }
    } else {
      const result = await createRoute(formData);
      if (result.status === 200) {
        alert(result.data);
        resetForm();
        onClose();
      } else {
        alert(result.data);
      }
    }
  };
  
  const resetForm = () => {
    setFormData({
      travellingFrom: "",
      travellingTo: "",
      categories: [
        { vehicleNumber: "", departureTime: "", vehicleType: "", price: "" },
      ],
    });
  };
  

  return (
    <div className="admin-modal-overlay">
      <div className="request-form admin-dialog-container">
        <div className="modal-header">
          <span className="close-button" onClick={onClose}>
            <IoCloseCircle style={{ margin: "10px 5px 5px 5px" }} />
          </span>
          <div className="logistic-header">
            <h5>Route Details</h5>
          </div>
        </div>
        <div className="logistics-flex">
          <div className="route">
            <div className="form-group route-path">
              <input
                type="text"
                value={formData.travellingFrom}
                placeholder="Travelling From"
                onChange={(e) =>
                  setFormData({ ...formData, travellingFrom: e.target.value })
                }
              />
              {errors.travellingFrom && (
                <span className="error">{errors.travellingFrom}</span>
              )}
            </div>
            <div className="form-group route-path">
              <input
                type="text"
                value={formData.travellingTo}
                placeholder="Travelling To"
                onChange={(e) =>
                  setFormData({ ...formData, travellingTo: e.target.value })
                }
              />
              {errors.travellingTo && (
                <span className="error">{errors.travellingTo}</span>
              )}
            </div>
          </div>

          <div className="logistic-header">
            <h5>Travelling Details</h5>
          </div>
          <br></br>
          <div className="route-path-header">
            <h5>Vehicle Number</h5>
            <h5>Vehicle Type</h5>
            <h5>Travel Time</h5>
            <h5 className="last">Fare</h5>
            <div className="option"></div>
          </div>
          <br></br>

          {formData.categories.map((category, index) => (
            <div key={index} className="category-group">
              <div
                className="route-path-header"
                style={{ backgroundColor: "whiteSmoke" }}
              >
                <div className="form-group">
                  <input
                    placeholder="Vehicle Number"
                    value={category.vehicleNumber}
                    onChange={(e) =>
                      handleCategoryChange(
                        index,
                        "vehicleNumber",
                        e.target.value
                      )
                    }
                  />
                </div>
                <div className="form-group">
                  <select
                    value={category.vehicleType}
                    onChange={(e) =>
                      handleCategoryChange(index, "vehicleType", e.target.value)
                    }
                  >
                    <option value="">--Select Vehicle Type--</option>
                    <option>Bus</option>
                    <option>Sienna</option>
                  </select>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Departure Time"
                    list="time-list"
                    value={category.departureTime}
                    onChange={(e) =>
                      handleCategoryChange(
                        index,
                        "departureTime",
                        e.target.value
                      )
                    }
                  />
                  <datalist id="time-list">
                    <option value="">--Select Your Time--</option>
                    <option value="07:00 AM<">07:00 AM</option>
                    <option value="09:00 AM">09:00 AM</option>
                    <option value="Night Bus (06:00 PM)">Night Bus (06:00 PM)</option>
                  </datalist>
                </div>
                <div className="form-group">
                  <input
                    type="number"
                    placeholder="Price"
                    value={category.price}
                    onChange={(e) =>
                      handleCategoryChange(index, "price", e.target.value)
                    }
                  />
                </div>
                <div className="option">
                  <MdDelete onClick={() => removeCategory(index)} style={{cursor:'pointer'}}></MdDelete>
                  <IoAddCircle onClick={addCategory} style={{cursor:'pointer'}}></IoAddCircle>
                </div>
              </div>
            </div>
          ))}
        </div>

        <button className="submit-button" onClick={createInventory}>
          {updateMood?'Update':'Create'} Route
        </button>
      </div>
    </div>
  );
};

export default NewRouteInventory;
